.github-link {
  position: relative;
  max-width: 18em;
  border: solid 1px rgb(61, 68, 77);
  max-height: 2.8em;
  font-size: large;

  &.light {
    border: solid 1px #d1d9e0;

    .heading {
      color: #1f2328;
      background-color: #f6f8fa;
      border-bottom: solid 1px #d1d9e0;

      .icon {
        fill: #1f2328;
      }
    }

    .subheading {
      background-color: white;
    }
  }

  .heading {
    max-width: 100%;
    text-align: left;
    padding: 0.2em 0.4em;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;

    color: white;
    background-color: rgb(21, 27, 35);
    border-bottom: solid 1px rgb(61, 68, 77);

    .icon {
      position: relative;
      top: 2px;
      height: 1em;
      margin-right: 0.5em;
      fill: white;
    }
  }

  .subheading {
    max-width: 100%;
    padding: 0.2em;
    box-sizing: border-box;
    justify-content: flex-end;
    font-size: small;
    color: rgb(145, 152, 161);
    background-color: rgb(13, 17, 23);

    a {
      max-width: 80%;

      img {
        display: block;
        height: 100%;
      }

      span {
        display: block;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}