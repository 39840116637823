:root {
  --pallet-fg-primary: #363732;
  --pallet-fg-secondary: #fcffd5;
  --pallet-bg-primary: #abecfd;
  --pallet-bg-secondary: #ffe356;
  --pallet-accent-purple: #53109c;

  --monospace-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--pallet-fg-primary);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

button {
  border: none;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.shrink {
  flex-shrink: 1;
}

.grow {
  flex-grow: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.icon {
  height: 1.2em;
}

.round {
  border-radius: 50%;
}

.rounded,
.rounded-1 {
  border-radius: 6px;
}

.rounded-3 {
  border-radius: 18px;
}

.code {
  font-family: var(--monospace-font-family);
}

.bg-white {
  background-color: white;
}

.bg-primary {
  background-color: var(--pallet-bg-primary);
}

.bg-secondary {
  background-color: var(--pallet-bg-secondary);
}

.shadow,
.shadow-1 {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
}

.shadow-2 {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

.shadow-3 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}