.nav-arrow {
  color: white;
  background-color: var(--pallet-fg-primary);
  font-weight: bold;
  font-size: x-large;
  width: 50px;
  height: 50px;

  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;

  transition: transform 0.1s ease-in-out;

  &:active {
    transform: scale(0.9);
  }
}