.page {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .scroll-container {
    width: 100%;
    height: 100%;
    align-items: center;

    .comic-index {
      margin-bottom: 4em;
      font-family: 'Comic Sans MS', sans-serif;
    }

    .comic {
      display: block;

      margin: 2em auto;
      margin-bottom: 1em;
      width: 40em;
      max-width: 100%;
    }

    .swipe-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 5vh;
    }
  }

  .nav-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 600px) {
      opacity: 0.3;
      top: unset;
      bottom: 0;
    }

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }
}