.footer {
  border-top: solid var(--pallet-fg-primary) 4px;
  border-bottom: solid var(--pallet-fg-primary) 4px;

  font-size: x-large;

  .content-row {
    justify-content: space-between;
    align-items: center;
    gap: 0.2em;
    padding: 0.8em 1.2em;
    width: 100%;
  }

  &:hover .peek {
    visibility: visible;
    position: relative;
  }

  .peek {
    visibility: hidden;
    position: absolute;
  }

  .icon {
    margin-right: 0.5em;
  }

  .text {
    color: var(--pallet-fg-primary);
    font-family: var(--monospace-font-family);
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    span.j {
      padding: 0 0.1em;
    }

    span.com {
      opacity: 0.8;
      font-size: medium;
    }
  }

  .item-row {
    justify-content: left;
    align-items: center;
    gap: 0.2em;
  }
}