.what-the-hex {
    width: 100%;
    height: 100%;

    display: flex;

    padding: 2em;
    box-sizing: border-box;

    .hash-icon {
        height: 100%;
        min-width: 100%;
        position: absolute;
        bottom: -15%;
        right: -30%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: radial-gradient(circle farthest-corner at 40px 40px, #E9F7FC 0, #54C2EF, #6aabdda0 100%);
    }

    &>.description {
        z-index: 2;
        width: 10em;
        min-width: 55%;
        height: calc(100% - 3em);
        align-items: center;

        @media (max-width: 600px) {
            min-width: 90%;
            align-self: center;
            width: unset;
        }

        .logo {
            position: relative;
            max-width: 100%;
            max-height: 40%;
            padding-bottom: 1em;
            left: 0.5em;

            @media (max-width: 600px) {
                max-width: 20em;
                max-height: 30%;
                align-self: flex-start;
                padding-bottom: 3em;
            }
        }

        .description-text {
            border-radius: 1em 0.2em;
            rotate: -2deg;

            box-shadow: 0.05em 0.1em 0.2em rgba(0, 0, 0, 0.5);

            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

            font-weight: bold;
            font-size: medium;
            text-overflow: ellipsis;
            text-align: center;
            padding: 2em 1em;
            color: var(--pallet-fg-primary);

            @media (max-width: 400px) {
                font-size: smaller;
            }

            .emoji {
                font-size: xx-large;
            }

            .icons {
                padding-top: 1em;
                display: flex;
                gap: 1em;
                justify-content: center;
                align-items: center;

                .icon {
                    height: 1.2em;
                    width: 1.2em;
                }
            }
        }
    }

    .mockup {
        position: absolute;
        bottom: 20%;
        right: 0;
        z-index: 1;
        max-width: 45%;
        max-height: 70%;

        @media (max-width: 600px) {
            top: 0%;
            width: 30%;
            min-width: 12em;
        }
    }

    .google-play-tag {
        position: absolute;
        z-index: 3;

        bottom: 1em;
        right: 1em;
    }
}