.home {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(0deg,
      #00212b 0%,
      #004c61 100%);
  border: 2px solid rgb(198, 198, 198);
  box-sizing: border-box;
  border-radius: inherit;

  font-size: large;

  @media (max-width: 600px) {
    font-size: medium;
  }

  .content {
    padding: 0.5em 0;
    padding-bottom: 0;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    width: 100%;
    box-sizing: content-box;
    color: var(--pallet-fg-primary);

    .title {
      display: inline;
      font-family: var(--monospace-font-family);
      font-size: 1.5em;
      width: 100%;
      padding: 0.5em;
      vertical-align: baseline;
      font-weight: bold;

      color: var(--pallet-fg-secondary);

      span.prompt {
        position: relative;
        bottom: 0.1em;
        padding: 0 0.3em;
        color: rgb(195, 195, 189);
        font-weight: normal
      }

      span.j {
        padding: 0 0.2em;
      }

      span.com {
        color: rgb(195, 195, 189);
        font-size: 0.6em;
      }
    }

    .description {
      position: relative;
      gap: 1em;
      padding: 2em;
      color: var(--pallet-fg-primary);
      background-color: rgba(255, 255, 255, 0.8);
      min-width: 100%;
      box-sizing: border-box;

      flex-grow: 0;
      align-items: center;

      font-size: 0.8em;

      .gems {
        gap: 1em;
      }

      .banner {
        position: absolute;
        bottom: -1em;
        width: 120%;
        rotate: -2deg;

        min-height: 2em;
      }
    }

    .signature {
      color: rgb(195, 195, 189);
      padding: 1em;
      gap: 0.5em;
      font-family: var(--monospace-font-family);
      font-size: 1.2em;

      .aliens {
        gap: 0.5em;
        justify-content: center;
      }
    }
  }

  .github-link {
    margin: 1em;
    margin-top: 0.5em;
    align-self: flex-end;
    min-width: unset;
    max-width: calc(100% - 3em);
    flex-shrink: 0;
  }
}