.notion-post {
  position: relative;
  width: 100%;
  height: 100%;

  color: rgb(55, 53, 47);
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";

  .header-image {
    min-width: 100%;
    max-height: 60%;
    object-fit: cover;
  }

  .body {
    align-items: start;
    padding: 1em;
    padding-left: 2em;
    padding-top: 0;

    margin-top: -65px;

    .emoji {
      padding-left: 0.1em;
      font-size: 100px;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 0.5em;
    }

    .attribute-row {
      align-items: center;

      padding-left: 1em;
      font-weight: light;

      .attribute {
        width: 8em;
        color: rgb(120, 119, 116);
      }

      div {
        align-items: center;
        gap: 0.4em;
      }

      svg {
        height: 1em;
        fill: rgb(120, 119, 116);
      }

      img {
        height: 1em;
      }

      .author-image {
        height: 1.2em;
      }
    }

    .spacer {
      min-height: 1em;
    }

    .links {
      align-self: flex-end;
      gap: 1em;
      align-items: center;
      max-width: 100%;

      .notion-link {
        a {
          padding: 0.2em;

          .icon {
            height: 2.5em;
          }
        }
      }
    }
  }
}