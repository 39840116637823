.construction-sign {
  position: relative;
  width: 100%;
  height: 2em;
  border-top: solid var(--pallet-fg-primary) 2px;
  border-bottom: solid var(--pallet-fg-primary) 2px;

  .bg {
    background-image: repeating-linear-gradient(45deg,
        var(--pallet-fg-primary),
        var(--pallet-fg-primary) 40px,
        var(--pallet-bg-secondary) 40px,
        var(--pallet-bg-secondary) 80px);

    width: 50%;
    height: 100%;

    &.left {
      float: right;
    }

    &.right {
      float: left;
      transform: scaleX(-1);
    }
  }

  .text-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: 0 0;
    transform: rotate(-3deg) translateX(-50%) translateY(-50%);
    min-width: 120%;
    opacity: 0.9;
    text-align: center;
    white-space: nowrap;
    color: var(--pallet-accent-purple);
    font-weight: bold;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: larger;
  }
}