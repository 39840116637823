.not-found {
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 10em;
    margin: 0;
    padding: 0 20px;
  }

  .detail {
    padding: 1em;
    transition: opacity 0.5s;

    &.hide {
      opacity: 0;
    }
  }
}